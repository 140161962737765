<template>
  <div class="busqueda">
    <div class="container">
      <el-row :gutter="20">
        <el-col :md="6" :sm="8" :xs="24">
          <fo-filtros
            :datos="resultados"
            :filtroMarca="marcas"
            :filtroCat="categorias"
            class="hidden-sm-and-down"
          />
      
        </el-col>
        <el-col :md="18" :sm="24" :xs="24" class="sin-borde">
          <el-skeleton
            style="width: 100%; min-height: 100vh"
            animated
            v-if="loading && !sinresultados"
          >
            <template slot="template">
              <el-card
                class="card-grilla"
                shadow="always"
                style="margin-top: 30px"
                :body-style="{ padding: '0px' }"
              >
                <el-row :gutter="20" class="listado" v-for="s in 10" :key="s">
                  <el-col :md="3" :sm="4" :xs="24" :offset="0">
                    <el-skeleton-item
                      variant="image"
                      style="margin: 10px; width: 70px; height: 70px"
                    />
                  </el-col>
                  <el-col :md="20" :sm="20" :xs="24" :offset="0">
                    <div style="padding: 14px">
                      <el-skeleton-item variant="h3" style="width: 50%" />
                      <div
                        style="
                          display: flex;
                          align-items: center;
                          justify-items: space-between;
                          margin-top: 16px;
                          height: 16px;
                        "
                      >
                        <el-skeleton-item
                          variant="text"
                          style="margin-right: 16px"
                        />
                        <el-skeleton-item variant="text" style="width: 30%" />
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </el-card>
            </template>
          </el-skeleton>
          <a
            @click.prevent="limpiarFiltros"
            style="
              text-align: right;
              float: right;
              display: block;
              color: #686868;
              font-size: 13px;
              margin-top: 10px;
              cursor: pointer;
            "
            href="#"
            >ver todos los productos</a
          >
          <div style="float: left">
            <fo-grilla
              :datos="resultados"
              v-if="!loading && resultados.length"
            />
          </div>
          <el-pagination
            v-if="!loading && resultados.length"
            @current-change="nextPage"
            style="margin-top: 20px; margin-bottom: 50px; float: right"
            :current-page="parseInt($route.query.pagina)"
            background
            :page-size="50"
            layout="total, prev, pager, next"
            :total="total_pages"
          ></el-pagination>
       <div>
       
       </div>
          <div class="no-result" v-if="sinresultados">
            <el-card shadow="always" :body-style="{ padding: '20px' }">
              <img src="@/assets/error.svg" alt />
              <h2>
                No se encontraron resultados para su búsqueda
                <br />
                <el-button type="danger" size="mini" @click="limpiarFiltros"
                  >ver todo</el-button
                >
              </h2>
            </el-card>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20" v-if="busqueda && sinresultados">
        <el-col :md="24">
          <div class="no-result">
            <el-card shadow="always" :body-style="{ padding: '20px' }">
              <img src="@/assets/error.svg" alt />
              <h2>
                No se encontraron resultados para su búsqueda
                <br />
                <el-button type="danger" size="mini" @click="limpiarFiltros"
                  >ver todo</el-button
                >
              </h2>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </div>
    
  </div>
</template>

<script>
import { HTTP } from "@/axios.js";
import foMixins from "@/mixins/foMixins.js";
import Vue2Filters from "vue2-filters";

import { EventBus } from "@/event-bus";

import FoFiltros from "@/components/FoFiltros.vue";
import FoGrilla from "@/components/FoGrilla.vue";
var VueScrollTo = require("vue-scrollto");

export default {
  mixins: [foMixins, Vue2Filters.mixin],
  components: {
    FoFiltros,
    FoGrilla,
  },
  data() {
    return {
      busqueda: false,
      loading: true,
      resultados: [],
      filtros: [],
      categorias: [],
      oferta: false,
      marcas: [],
      query: {},
      nombre: "",
      sinprecio: 0,
      sinimagen: 0,
      borrados:0,
      params: {},
      page: 1,
      id_producto: 0,
      total_pages: 0,
      sinresultados: false,
    };
  },
  //   watch: {
  //     $route(to, from) {
  //       // react to route changes...
  //       console.log(to);
  //       console.log(from);
  //       if(to.$route.params)
  //       {
  //  this.buscarProducto();
  //       }

  //     },
  //   },
  created() {
    if (!this.$route.query.pagina) {
      this.$router
        .push({ name: "catalogo", query: { pagina: 1 } })
        .catch(() => {});
      this.buscarProducto();
    } else {
      this.buscarProducto();
    }
    EventBus.$on("actualizarLista", () => {
      this.buscarProducto();
    });
    EventBus.$on("eliminarFiltros", (marcas, cat) => {
      this.$router
        .push({ name: "catalogo", query: { pagina: 1 } })
        .catch(() => {});
      this.categorias = cat;
      this.marcas = marcas;
      this.buscarProducto();
      // console.log(marcas,cat);
    });
    EventBus.$on("filtrar", (campo, valor) => {
      console.log(campo, valor);
      this[campo] = valor; //"'"+valor.join("','")+"'"

      this.$router
        .push({ name: "catalogo", query: { pagina: 1 } })
        .catch(() => {});
      this.buscarProducto();
    });
    EventBus.$on("encontrados", (data) => {
      console.log('encontrado', data.data);
     
      this.resultados = [];
            this.total_pages = data.total;
             this.loading = true;
      setTimeout(() => {
        this.resultados = data.data;
            this.total_pages = data.total;
             this.loading = false;
             console.log(this.resultados.length);
          
      }, 100);
            
    });
  },
  methods: {
    limpiarFiltros() {
      console.log("sds");
      this.sinimagen = 0;
      this.sinprecio = 0;
      this.borrados = false;
      this.categorias = []
      this.marcas = []
      EventBus.$emit("limpiarBusqueda", 0);
      EventBus.$emit("limpiarCheckBox", 0);
      
    },
    nextPage(p) {
      console.log(p);
      this.$router
        .push({ name: "catalogo", query: { pagina: p } })
        .catch(() => {});
      this.buscarProducto();
    },
    buscarProducto() {
      this.busqueda = false;
      this.loading = true;
      // this.resultados = [];
      // let nombre = this.$route.query.nombre || "";
      let id_producto = this.id_producto;
      let sinprecio = this.sinprecio;
      let sinimagen = this.sinimagen;
      let borrados = this.borrados;
      let nombre = this.nombre || "";
      let categorias = this.categorias.map((c) => c.id) || "";
      let oferta = this.oferta || false;
      let marcas = this.marcas.map((m) => m.id) || "";
      //   let seleccion = this.$route.query.seleccion || 0;
      //   let hits = this.$route.params.nombres || false;
      //  this.$route.query.pagina = p  ;
 VueScrollTo.scrollTo(".menu");
      HTTP.post("/noa/getProductos?page=" + this.$route.query.pagina, {
        nombre,
        categorias,
        marcas,
        oferta,
        pedido_id: this.pedido,
        sinprecio,
        sinimagen,
        borrados,
        id_producto
      })
      .then((res) => {
       
        if (res.data.data.length) {
          this.sinresultados = false;
          res.data.data.map((r) => {
            r.cantidad ? (r.agregado = true) : (r.agregado = false);
            r.fotos = r.fotos.reverse();
          });
          setTimeout(() => {
            this.busqueda = true;
            this.loading = false;
            this.resultados = res.data.data;
            this.total_pages = res.data.total;
            // console.log(this.resultados);
             VueScrollTo.scrollTo(".menu");
          }, 500);
        } else {
          this.sinresultados = true;
           VueScrollTo.scrollTo(".menu");
        }
      })
      .catch((err) => {
        // console.log(err);
        VueScrollTo.scrollTo(".menu");

         this.$alert('No pudimos conectarnos con el servidor, verifique su conexíon a internet y actualice la página', 'Error de conexíon ' + err, {
           confirmButtonText: 'Aceptar',
           
         })
         
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.busqueda {
  background: #f6f9fc;
  //  border-top: 1px solid #f1f1f1;
  // height: 100%;
  min-height: calc(100vh - 170px);
  position: relative;
}
.cargando {
  min-height: calc(100vh - 150px);
}
.no-result {
  text-align: center;
  margin-top: 30px;
}
.no-result h2 {
  margin: 0;
  // background: #e03e16;
  padding: 30px 10px;
  line-height: 1.2;
  font-weight: normal;
  color: #e03e16;
  border-radius: 2px;
}
.no-result img {
  width: 200px;
  padding: 20px;
  display: block;
  margin: 0 auto;
}
@media screen and (max-width: 768px) {
  .no-result {
    text-align: center;
    margin-top: 30px;
  }
  .no-result h2 {
    font-size: 18px;
    margin: 0;
    background: #e3f2fd;
    padding: 10px;
    line-height: 1.2;
  }
}
</style>
