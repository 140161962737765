<template>
  <div>
    <div
      v-if="recuperando"
      v-loading="recuperando"
      element-loading-text="Recuperando información del pedido..."
      element-loading-spinner="el-icon-loading"
      style="height: calc(100vh - 240px)"
    ></div>
    <div v-if="!recuperando">
      <!-- <div class="vacio" v-if="finalizado">
        <img src="@/assets/completado.svg" alt="" />
        <h1>Pedido enviado correctamente</h1>
        <p>
          Recibimos su pedido, en caso de ser necesario nos comunicaremos con
          usted por los siguiente medios:
        </p>
        <ul>
          <li>
            Nombre: <strong> {{ datos_cliente.nombre }}</strong>
          </li>
          <li>
            Correo: <strong> {{ datos_cliente.correo }}</strong>
          </li>
          <li>
            Teléfono: <strong> {{ datos_cliente.telefono }}</strong>
          </li>
        </ul>
        <div style="display: block; margin: 10px auto; width: 200px">
          <el-button type="primary" size="default" @click="regresar"
            >Regresar</el-button
          >
        </div>
      </div> -->
      <div class="vacio" v-if="!detalle.length && !finalizado">
        <img src="@/assets/vacio.svg" alt="" />
        <h1>El pedido está vacio</h1>
        <el-button
          type="primary"
          size="default"
          style="display: block; margin: 0 auto"
          @click="cerrarDetalles"
          >Agregar productos</el-button
        >
      </div>
      <div class="pedido" v-if="detalle.length && !finalizado">
        <el-table class="hidden-xs-only"  :data="detalle" border stripe fit height="calc(100vh - 240px)" size="mini">
          <el-table-column type="index" width="50" > </el-table-column>
          <el-table-column prop="nombre" label="Producto">
            <template slot-scope="scope">
              {{ scope.row.nombre }} <strong>{{ scope.row.codigo }} </strong>
              <br />
              <small style="color: #e91e63" v-if="scope.row.bultos">
                bultos: {{ scope.row.bultos }} - unidades x bulto:
                {{ scope.row.xbulto }} 
              </small>
              <!-- <small style="color: #2196f3" v-if="scope.row.oferta">
                Oferta: descuento {{ ((scope.row.oferta * 100) / scope.row.precio).toFixed(1) }}%
              </small> -->
            </template>
          </el-table-column>
          <el-table-column prop="cantidad" label="Cantidad" width="120">
            <template slot-scope="scope">
              <el-input-number
                v-model="scope.row.cantidad"
                size="mini"
                style="width: 100%"
                :step="1"
                :min="1"
                :controls="true"
                controls-position="right"
                @change="updateCantidadProducto(scope.row)"
              >
              </el-input-number>
            </template>
          </el-table-column>
          <el-table-column
            prop="precio_compra"
            :label="detalle[0].iva ? 'Precio con iva' : 'Precio sin IVA'"
            width="120"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.oferta" style="color: #2196f3">
                {{ scope.row.precio_compra | currency }}
              </span>
              <span v-else>
                {{ scope.row.precio_compra | currency }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            prop="precio_compra"
            :label="detalle[0].iva ? 'Subtotal con iva' : 'Subtotal sin IVA'"
            width="130"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.oferta" style="color: #2196f3">
                {{ (scope.row.precio_compra * scope.row.cantidad) | currency }}
              </span>
              <span v-else>
                {{ (scope.row.precio_compra * scope.row.cantidad) | currency }}
              </span>
            </template>
          </el-table-column>
             <el-table-column
            prop=""
           
            width="80"
          >
            <template slot-scope="scope">
               <el-button type="primary" size="mini" plain @click="borrar(scope.row)" icon="el-icon-delete"></el-button>
               
            </template>
          </el-table-column>
        </el-table>
 <el-table class="hidden-sm-and-up"  :data="detalle" border stripe fit height="450" size="mini">
          
          <el-table-column prop="nombre" label="Producto">
            <template slot-scope="scope">
              {{ scope.row.nombre }} <strong>{{ scope.row.codigo }} </strong>
              <br>
 <strong style="color: #c9510b">  Precio sin IVA {{ scope.row.precio_compra | currency }}</strong>
             
            
              <br />
              <strong>   Subtotal sin IVA {{ (scope.row.precio_compra * scope.row.cantidad) | currency }}</strong>
            
             
              <br>
              <small style="color: #e91e63" v-if="scope.row.bultos">
                bultos: {{ scope.row.bultos }} - unidades x bulto:
                {{ scope.row.xbulto }} 
              </small>
              <!-- <small style="color: #2196f3" v-if="scope.row.oferta">
                Oferta: descuento {{ ((scope.row.oferta * 100) / scope.row.precio).toFixed(1) }}%
              </small> -->
            </template>
          </el-table-column>
          <el-table-column prop="cantidad" label="Cantidad" width="120">
            <template slot-scope="scope">
              <el-input-number
                v-model="scope.row.cantidad"
                size="mini"
                style="width: 100%"
                :step="1"
                :min="1"
                :controls="true"
                
                @change="updateCantidadProducto(scope.row)"
              >
              </el-input-number>
            </template>
          </el-table-column>
       
        
        
             <el-table-column
            prop=""
           
            width="80"
          >
            <template slot-scope="scope">
               <el-button type="primary" size="mini" plain @click="borrar(scope.row)" icon="el-icon-delete"></el-button>
               
            </template>
          </el-table-column>
        </el-table>
    

        <div style="background: #f1f1f1; padding: 10px">
          <el-row :gutter="20">
            <el-col :md="8" :offset="0">
              <div class="resumen">
                <ul>
                  <li>Items: {{ detalle.length }}</li>
                  <li>Cantidad: {{ totales().cantidad }}</li>
                  <li>Items con ofertas: {{ totales().ofertas }}</li>
                </ul>
              </div>
            </el-col>
            <el-col :md="16" :offset="0">
              <div style="">
                <table class="table table-bordered" style="font-size:14px;text-align: right">
                  <tbody>
<tr>
                    <td> SubTotal Pedido sin IVA</td>
                    <th>{{ totales().total | currency }}</th>
                  </tr>
                  <tr>
                    <td> IVA</td>
                    <th>{{ ((totales().total * 1.21) - totales().total)| currency }}</th>
                  </tr>
                    <tr>
                    <td> Total Pedido</td>
                    <th>{{ ((totales().total * 1.21) )| currency }}</th>
                  </tr>
                  </tbody>
                  
                </table>
                <!-- <h3>
                  Total Pedido <span v-if="detalle[0].iva">con iva</span
                  ><span v-if="!detalle[0].iva">sin iva</span>:
                  
                </h3>
                 <h3>
                  Total Pedido <span v-if="detalle[0].iva">con iva</span
                  ><span v-if="!detalle[0].iva">sin iva</span>:
                  {{ totales().total | currency }}
                </h3> -->
                <div style="float:right">
                <el-button
                  type="default"
                  size="default"
                  style=""
                  class="hidden-xs-only"
                  @click="cerrarDetalles"
                  >Agregar más productos</el-button
                >
                <el-button
                  type="primary"
                  size="default"
                  style=""
                   class="hidden-xs-only"
                  :loading="finalizando"
                  @click="finalizarPedido"
                  >Realizar Pedido</el-button
                >
                </div>
                  <el-button
                  type="default"
                  size="default"
                  style="display:inline-block;width:50%;  white-space:normal"
                  class="hidden-sm-and-up"
                  @click="cerrarDetalles"
                  >Agregar más productos</el-button
                >
                <el-button
                  type="primary"
                  size="default"
                  style="display:inline-block;width:40%;  white-space:normal"
                   class="hidden-sm-and-up"
                  :loading="finalizando"
                  @click="finalizarPedido"
                  >Realizar Pedido</el-button
                >
                <br>
              </div>
            </el-col>
            <el-col :md="24" :offset="0">
              <small style="display:block; text-align:right; margin-top:10px; padding-top:10px">Pedido sujeto a stock disponible, algunos productos podrían no encontrarse actualmente en stock. Para más información comunicarse a nuestro centro de atención</small>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from "@/axios";
import { EventBus } from "@/event-bus";
import foMixins from "@/mixins/foMixins.js";
export default {
  mixins: [foMixins],
  data() {
    return {
      detalle: [],
      recuperando: true,
      finalizado: false,
      finalizando: false,
      total_pedido: 0,
      total_productos: 0,
      total_cantidad: 0,
    };
  },
  created() {
    setTimeout(() => {
      this.recuperando = false;
    }, 2000);
    this.getPedido();
    EventBus.$on("actualizarCarro", () => {
      console.log("actualizarCarro", this.detalle);
      this.getPedido();
    });
  },
  methods: {
     isOferta(r) {
      return r.oferta < r.precio && r.oferta  && r.oferta != 1;
    },
    finalizarPedido() {
      this.finalizando = true;
      if (!this.pedido) {
        this.actualizarPedido();
      }
      setTimeout(() => {
        HTTP.post("/noa/finalizarPedido", {
          cliente_id: this.cliente,
          pedido_id: this.pedido,
          total_pedido: this.total_pedido,
          total_productos: this.detalle.length,
          total_cantidad: this.total_cantidad,
          correo: this.datos_cliente.correo,
        })
          .then((res) => {
            console.log('finalizarPedido',res.data);
            this.finalizando = false;
            EventBus.$emit("actualizarLista", 1);
            EventBus.$emit("agregados", 0);
            EventBus.$emit("cerrarDetalles", false);
            
            this.recuperando = false;
            window.sessionStorage.removeItem("pedido");
            window.sessionStorage.removeItem("agregados");
            let cliente_aux = JSON.parse(
              window.sessionStorage.getItem("cliente")
            );
            cliente_aux.pedido = 0;
            window.sessionStorage.setItem(
              "cliente",
              JSON.stringify(cliente_aux)
            );

            setTimeout(() => {
               this.$router.push({
              name: "detallePedido",
              params: {pedido: res.data},
              query: { origen: "finalizar" },
            });
            }, 250);
           
          })
          .catch(() => {
              window.sessionStorage.removeItem("pedido");
            window.sessionStorage.removeItem("agregados");
            this.finalizando = false;
            this.$alert(
              "No pudimos confirmar su pedido, verifique su conexión a internet y reintente"
            );
          });
      }, 100);
    },
    updateCantidadProducto(p) {
      console.log(p);
      HTTP.post("/noa/updateCantidadProducto", {
        id: p.id,
        pedido_id: p.pedido_id,
        cantidad: p.cantidad,
      })
        .then(() => {
          console.log("actualizado");
        })
        .catch(() => {
          this.$alert(
            "No se pudo actualizar la cantidad del producto, reintente"
          );
        });
    },
    borrar(p, index) {
      this.$confirm("Eliminar " + p.nombre + " del pedido", "Quitar producto", {
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
        type: "danger",
      }).then(() => {
        HTTP.post("/noa/eliminarProductoPedido", {
          id: p.id,
          pedido_id: p.pedido_id,
        }).then((res) => {
          if (res.data) {
            this.detalle.splice(index, 1);
            this.$message({
              type: "success",
              message: "Producto eliminado",
            });
          } else {
            this.$message({
              type: "error",
              message: "no se pudo eliminar el producto",
            });
          }
        });
      });
    },
    totales() {
      let totales = {};

      this.total_cantidad = totales.cantidad = this.detalle.reduce((a, b) => {
        return a + b.cantidad;
      }, 0);
      totales.unitarios = this.detalle.reduce((u, uw) => {
        return parseFloat(u) + parseFloat(uw.precio_compra);
      }, 0);
      this.total_pedido = totales.total = this.detalle.reduce((t, tu) => {
        return parseFloat(t) + parseFloat(tu.precio_compra) * tu.cantidad;
      }, 0);

      totales.ofertas = this.detalle.filter((o) => {
        return o.oferta > 0;
      }).length;
      // console.log(totales);
      return totales;
    },
    regresar() {
      window.location.reload();
    },
    cerrarDetalles() {
      EventBus.$emit("cerrarDetalles", false);
    },
    getPedido() {
      setTimeout(() => {
        HTTP.post("/noa/getPedido", {
          cliente_id: this.cliente,
          pedido_id:
            this.pedido || parseInt(window.sessionStorage.getItem("pedido")),
        }).then((res) => {
          console.log(res);
          if (res.data.length) {
            this.finalizado = false;
          }

          this.detalle = res.data;
        });
      }, 100);
    },
  },
};
</script>
<style>

</style>
<style lang="scss" scoped>
tr.table-footer td {
  padding: 10px 4px !important;
  font-weight: bold;
  font-size: 16px !important;
  background: #cddc39 !important;
}
.table-striped-noa > tbody > tr:nth-child(odd) > td,
.table-striped-noa > tbody > tr:nth-child(odd) > th {
  background-color: #fce4ec5e;
}
.table-hover-noa {
  margin-bottom: 0 !important;
}
.tableFixHead {
  overflow: auto;
  // height: 600px;
}
.tableFixHead thead th {
  position: sticky;
  top: -10px;
  z-index: 1;
  padding: 20px;
}

.table-hover-noa > tbody > tr:hover {
  background-color: #ffeb3b;
  cursor: pointer;
}
.vacio {
  padding: 20px;
}
.vacio img {
  display: block;
  margin: 0 auto;
  max-width: 220px;
}
.vacio h1 {
  text-align: center;
}
.vacio p {
  text-align: center;
  margin: 0 auto;
  // width: 320px;
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.vacio ul {
  width: 320px;
  text-align: left;
  margin: 0 auto;
}
</style>