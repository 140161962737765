import axios from "axios";

// export const baseUrl = `https://catalogo.proyecto.ar/api/public`;
// export const uploadFilesUrl = `https://catalogo.proyecto.ar/api/public/noa/subirImagen`;
// export const FILES_PATH = `https://catalogo.proyecto.ar`;

export const baseUrl = `https://catalogo.noacomercial.com.ar/api/public`;
export const uploadFilesUrl = `https://catalogo.noacomercial.com.ar/api/public/noa/subirImagen`;
export const FILES_PATH = `https://catalogo.noacomercial.com.ar`;
// export const imgUrl = ``
// export const imgUrl = ``
export const HTTP = axios.create({
  baseURL: baseUrl, 
  headers: {
    // 'Authorization': `Bearer ${window.sessionStorage.getItem('token')}`,
    // 'Accept': 'application/json',
    // 'Content-Type': 'application/json',
    // 'Content-Type': 'multipart/form-data' ,
    // 'Content-Type': 'application/x-www-form-urlencoded'
  },
  crossDomain: true,
});
