import { HTTP} from "@/axios.js";
// import {EventBus} from "@/event-bus"
const foMixins = {
  data() {
    return {
      // verPrecios: window.sessionStorage.getItem('cliente') || window.sessionStorage.getItem('admin') || 0,
      isAdmin:undefined,
      cliente: window.sessionStorage.getItem('cliente') ? JSON.parse(window.sessionStorage.getItem('cliente')).cliente_id : 0,
      user: window.sessionStorage.getItem('cliente') ? JSON.parse(window.sessionStorage.getItem('cliente')).id : 0,
      datos_cliente: window.sessionStorage.getItem('cliente') ? JSON.parse(window.sessionStorage.getItem('cliente')) : {},
      pedido: 0, 
    };
  },
  created()
  {
    if(window.sessionStorage.getItem('pedido'))
    {
      this.pedido = parseInt(window.sessionStorage.getItem('pedido'))
    }
    else if (window.sessionStorage.getItem('cliente'))
    {
      this.pedido = JSON.parse(window.sessionStorage.getItem('cliente')).pedido
    }
    if( window.sessionStorage.getItem("cliente"))
    {
      this.isAdmin =  (JSON.parse(window.sessionStorage.getItem("cliente")).rol == 99) ? true : false
    } 
  },
  methods: {
    actualizarPedido()
    {
      setTimeout(() => {
        this.pedido = parseInt(window.sessionStorage.getItem('pedido'))
      }, 100);
    },
    getCategorias() {
      HTTP.get("/noa/getCategorias").then((res) => {
        this.categorias = res.data;
      });
    },
    getMarcas() {
      HTTP.get("/noa/getMarcas").then((res) => {
        this.marcas = res.data;
      });
    },
    groupBy(xs, key) {
      return xs.reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    },
    groupByMultiple(array, f) {
      let groups = {};
      array.forEach(function(o) {
        var group = JSON.stringify(f(o));
        groups[group] = groups[group] || [];
        groups[group].push(o);
      });
      return Object.keys(groups).map(function(group) {
        return groups[group][0];
      });
    },
    getRuta(nombre, query = false, params = false) {
      this.$router
        .push({ name: nombre, query: query, params: params })
        .catch(() => {});
    },
  },
};
export default foMixins