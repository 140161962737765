<template>
  <el-card shadow="always" class="filtros" :body-style="{ padding: '10px' }">
    <div>
        <h3 v-if="filtroMarca.length || filtroCat.length" style="margin-top:-1px">Filtros</h3>
        <div v-if="filtroMarca">
         
          <el-tag
  v-for="(tag,index) in filtroMarca"
  :key="tag.id"
  closable
  size="mini"
  @close="handleClose('filtroMarca',index)"
  style="margin-right:5px; margin-top:5px;"
  type="warning">
  {{tag.nombre}}
</el-tag>
        </div>
         <div v-if="filtroCat">
         
          <el-tag
  v-for="(cat,index) in filtroCat"
  :key="cat.id"
  closable
  size="mini"
  @close="handleClose('filtroCat',index)"
  style="margin-right:5px; margin-top:5px;"
  type="danger">
  {{cat.nombre}}
</el-tag>
        </div>
      <el-button  v-if="filtroMarca.length || filtroCat.length" size="mini" style="margin:10px auto; width:100%" @click="eliminarFiltros">Eliminar filtros</el-button>
      <el-collapse v-model="activeNames">
          <el-collapse-item name="admin" v-if="isAdmin">
          <template slot="title">
            <h3>ADMIN</h3>
          </template>
           <el-checkbox
          v-model="sinprecio"
              :label="'Solo productos con ofertas'"
              @change="filtrar('sinprecio', sinprecio)"
              style="display: block; width: 100%; text-transform: capitalize;margin-top: 15px"
              >productos sin precio
              </el-checkbox>
               <el-checkbox
          v-model="sinimagen"
              :label="'Solo productos con ofertas'"
              @change="filtrar('sinimagen', sinimagen)"
              style="display: block; width: 100%; text-transform: capitalize;margin-top: 15px"
              >productos sin imágen
              </el-checkbox>
                <el-checkbox
          v-model="borrados"
              :label="'Solo productos con ofertas'"
              @change="filtrar('borrados', borrados)"
              style="display: block; width: 100%; text-transform: capitalize;margin-top: 15px"
              >productos borrados
              </el-checkbox>
        </el-collapse-item>
      <el-collapse-item name="ofertas">
          <template slot="title">
            <h3>Ofertas</h3>
          </template>
           <el-checkbox
          v-model="oferta"
              :label="'Solo productos con ofertas'"
              @change="filtrar('oferta', oferta)"
              style="display: block; width: 100%; text-transform: capitalize;margin-top: 15px"
              >Solo productos con ofertas
              </el-checkbox>
        </el-collapse-item>
        <el-collapse-item name="rubros">
          <template slot="title">
            <h3>Rubros</h3>
          </template>
          <el-checkbox-group v-model="categoria" @change="filtrar('categorias', categoria)"  style="margin-top: 15px">
            <el-checkbox
              v-for="l in categorias"
              :label="l"
              :key="l.id"
              style="display: block; width: 100%; text-transform: capitalize"
              >{{ l.nombre }}</el-checkbox
            >
          </el-checkbox-group>
        </el-collapse-item>
         <el-collapse-item name="marcas">
          <template slot="title">
            <h3>Marcas</h3>
          </template>
          <el-checkbox-group v-model="marca" @change="filtrar('marcas', marca)"  style="margin-top: 15px">
            <el-checkbox
              v-for="m in marcas"
              :label="m"
              :key="m.id"
              style="display: block; width: 100%; text-transform: capitalize"
              >{{ m.nombre }}</el-checkbox
            >
          </el-checkbox-group>
        </el-collapse-item>
      </el-collapse>
    </div>
  </el-card>
</template>

<script>
import { EventBus } from "@/event-bus";
import { HTTP } from "@/axios";
import foMixins from "@/mixins/foMixins.js";

export default {
  props: ["datos", "filtroMarca", "filtroCat"],
  mixins: [foMixins],

  data() {
    return {
      paso: 1,
      filtros: this.datos,
      marca: [],
      oferta:false,
      categoria: [],
      precio:false,
      sinprecio:false,
      sinimagen:false,
      borrados:false,
      imagen:false,
     categorias: [],
      marcas: [],
      activeNames: ["rubros", "marcas", "ofertas"],
      isAdmin: window.sessionStorage.getItem('admin'),

    };
  },
  created() {
    if(this.isAdmin)
    {
      this.activeNames =["admin"]
    }
    this.getCategorias();
    this.getMarcas();
    EventBus.$on('limpiarCheckBox', () => {
       this.marca = []
       this.categoria = []
       
    })
    // this.getFiltros(this.datos);
    // EventBus.$on("filtrados", (f) => {
    //   this.getFiltros(f);
    //   this.paso += 1;
    // });
  },
  methods: {

    eliminarFiltros()
    {
       EventBus.$emit('eliminarFiltros', [], [])
       this.marca = []
       this.categoria = []
       this.oferta = false
    },
    handleClose(campo, valor)
    {
        this[campo].splice(valor,1)
        setTimeout(() => {
          this.marca = this.filtroMarca
       this.categoria = this.filtroCat
         EventBus.$emit('eliminarFiltros', this.filtroMarca, this.filtroCat)

        }, 100);
    },
     getCategorias() {
      HTTP.get("/noa/getCategorias").then((res) => {
        this.categorias = res.data;
      });
    },
    getMarcas() {
      HTTP.get("/noa/getMarcas").then((res) => {
        this.marcas = res.data;
      });
    },
    filtrar(campo, valor) {
      console.log(valor);
      console.log(campo);
      // switch (campo) {
      //   case "forma":
      //     this.selector[0] = this.forma;
      //     break;
      //   case "potencia":
      //     this.selector[1] = this.presentacion[0];
      //     this.selector[2] = this.presentacion[1];
      //     break;
      //   case "unidades":
      //     this.selector[3] = this.cantidad;

      //     break;
      // }

      // console.log(valor, this.selector);

      setTimeout(() => {
        EventBus.$emit("filtrar", campo, valor);
      }, 100);
    },
    getFiltros(datos) {
      this.rubros = Object.keys(this.groupBy(datos, "categoria"));
      this.marcas = Object.keys(this.groupBy(datos, "marca"));
     
    },
  },
};
</script>

<style lang="scss" >
.radio-forma {
  display: block;
  width: 100%;
  text-transform: capitalize;
}

.radio-forma .el-radio {
  width: 95%;
}
.radio-forma span.el-radio__label:hover {
  border-left: 3px solid #e91e63;
  background: #fafafa;
}
.radio-forma span.el-radio__label {
  font-size: 16px !important;
  padding: 10px 0px;
  padding-left: 10px;
  white-space: normal;
  display: inline-block;
  word-break: break-word;
  // background: #f1f1f1;
  width: 100%;
  display: block;
  border: 1px solid #f1f1f1;
  border-radius: 4px;
  transition: all 0.2s;
}

.radio-forma span.el-radio__input > span {
  display: none;
}
.radio-forma.el-radio.is-bordered {
  padding: 12px;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  min-height: 40px;
  height: auto;
  margin-top: 10px;
}
.radio-forma.el-radio.is-bordered + .el-radio.is-bordered {
  margin-left: 0px;
}
.radio-forma.el-radio__input {
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  display: none;
  line-height: 1;
  position: relative;
  vertical-align: middle;
}
.selector-titulo {
  display: block;
  /* margin-bottom: -10px; */
  margin-top: 10px;
  color: #e91e63;
  font-size: 14px;
  border-bottom: 1px dashed #e91e634d;
  padding-bottom: 4px;
}
.filtros {
  margin-top: 30px;
}
.filtros h3 {
  margin: 10px 0px;
  border-bottom: 1px solid #fd056c2a;
}
</style>