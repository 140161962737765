<template>
  <div class="container footer-bottom">
    <img src="@/assets/logo.png" class="logo" alt />

    <el-row :gutter="20">
      <el-col :md="{ span: 8, offset: 8 }" :sm="{ span: 12, offset: 6 }">
        <el-card
          shadow="always"
          :body-style="{ padding: '0px 30px' }"
          class="login"
          v-if="vista === 'login'"
        >
          <div slot="header">
            <span>Bienvenid@</span>
            <br />
            <small>Para continuar ingrese sus datos</small>
          </div>
          <el-form :model="login" ref="form" :label-position="'top'" :inline="false" size="normal">
            <el-form-item
              label="Usuario"
              prop="user"
              :rules="[
                {
                  required: true,
                  message: 'campo obligatorio',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input v-model="login.user" @keypress.native.enter="submitForm('form')"></el-input>
            </el-form-item>
            <el-form-item
              label="Contraseña"
              size="normal"
              prop="pass"
              :rules="[
                {
                  required: true,
                  message: 'campo obligatorio',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                v-model="login.pass"
                @keypress.native.enter="submitForm('form')"
                show-password
              ></el-input>
            </el-form-item>

            <el-form-item>
              <el-button
                type="primary"
                class="btn-block"
                :loading="cargando"
                @click="submitForm('form')"
              >INGRESAR</el-button>
              <el-button
                type="link"
                style="margin-left:-1px"
                size="small"
                @click="vista = 'recuperar'"
              >Olvide mis datos</el-button>
              <el-button type="info" size="small" @click="vista = 'registro'">Registrarme</el-button>
            </el-form-item>
          </el-form>
        </el-card>
        <el-card
          shadow="always"
          :body-style="{ padding: '0px 30px' }"
          class="login"
          v-if="vista === 'recuperar'"
        >
          <div slot="header">
            <span>Recuperar contraseña</span>
            <br />
            <small>Formulario de recuperación de datos</small>
          </div>
          <small style="padding-top:20px; display:block">
            Por favor ingrese su usuario o la dirección de correo electrónico.
            <br />
          </small>

          <el-form
            :model="recuperar"
            ref="form"
            :label-position="'top'"
            :inline="false"
            size="normal"
            v-if="!recuperar.msj"
          >
            <el-form-item label="CUIT" prop="cuit">
              <el-input v-model="recuperar.cuit"></el-input>
            </el-form-item>
            <!-- <el-form-item
              label="Correo"
              prop="correo"
            
            >
              <el-input v-model="recuperar.correo"></el-input>
            </el-form-item>-->
            <!-- <el-form-item
              label="Teléfono"
              prop="telefono"
            
            >
              <el-input v-model="recuperar.telefono"></el-input>
            </el-form-item>-->
            <!-- <small style="color:red">Si no recuerda ninguno de estos datos comuniquese a contacto@noacomercial.com.ar</small> -->
            <el-form-item>
              <el-button
                :loading="recuperando"
                type="primary"
                class="btn-block"
                @click="recuperarPass()"
              >RECUPERAR CONTRASEÑA</el-button>
              <el-button
                type="link"
                style="margin-left:-1px"
                size="small"
                @click="vista = 'login'"
              >regresar</el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :md="{ span: 18, offset: 3 }" :sm="{ span: 12, offset: 6 }">
        <el-card
          shadow="always"
          :body-style="{ padding: '0px 30px' }"
          class="login"
          v-if="vista === 'registro'"
        >
          <div slot="header">Nuevo Usuario</div>

          <div v-if="agregado" style="padding:30px">
            <h1>Nueva cuenta agregada</h1>
            <p>Su cuenta ha sido creada en forma exitosa y una vez aprobada recibirá un correo con su usuario y contraseña.</p>
            <br />
            <strong>Noa Comercial</strong>
          </div>
          <el-form
            :model="registro"
            ref="registro"
            :label-position="'top'"
            :inline="false"
            size="medium"
            v-if="!agregado"
          >
            <el-row :gutter="20">
              <el-col :md="16">
                <el-form-item
                  label="Razon Social"
                  prop="razon_social"
                  :rules="[
                    {
                      required: true,
                      message: 'campo obligatorio',
                      trigger: 'blur',
                    },
                  
                  ]"
                >
                  <el-input :min="4" v-model="registro.razon_social"></el-input>
                </el-form-item>
              </el-col>

              <el-col :md="8">
                <el-form-item
                  label="CUIT"
                  prop="cuit"
                  :rules="[
                    {
                      required: true,
                      message: 'campo obligatorio',
                      trigger: 'blur',
                    },
                  
                  ]"
                >
                  <el-input v-model="registro.cuit" :min="8"></el-input>
                </el-form-item>
              </el-col>
              <el-col :md="12">
                <el-form-item label="Nombre de fantasía" prop="fantasia">
                  <el-input v-model="registro.fantasia"></el-input>
                </el-form-item>
              </el-col>
              <el-col :md="12">
                <el-form-item
                  label="Nombre persona de contacto"
                  prop="nombre"
                  :rules="[
                    {
                      required: true,
                      message: 'campo obligatorio',
                      trigger: 'blur',
                    },
                  
                  ]"
                >
                  <el-input :min="4" v-model="registro.nombre"></el-input>
                </el-form-item>
              </el-col>
              <el-col :md="16">
                <el-form-item
                  label="Email"
                  prop="correo"
                  :rules="[
                    {
                      required: true,
                      message: 'campo obligatorio',
                      trigger: 'blur',
                    },
                  
                  ]"
                >
                  <el-input v-model="registro.correo"></el-input>
                </el-form-item>
              </el-col>
              <el-col :md="8">
                <el-form-item
                  label="Teléfono"
                  prop="telefono"
                  :rules="[
                    {
                      required: true,
                      message: 'campo obligatorio',
                      trigger: 'blur',
                    },
                  
                  ]"
                >
                  <el-input v-model="registro.telefono"></el-input>
                </el-form-item>
              </el-col>
              <el-col :md="16">
                <el-form-item
                  label="Dirección"
                  prop="direccion"
                  :rules="[
                    {
                      required: true,
                      message: 'campo obligatorio',
                      trigger: 'blur',
                    },
                  
                  ]"
                >
                  <el-input v-model="registro.direccion" :min="8"></el-input>
                </el-form-item>
              </el-col>
              <el-col :md="12">
                <el-form-item
                  label="Localidad"
                  prop="localidad"
                  :rules="[
                    {
                      required: true,
                      message: 'campo obligatorio',
                      trigger: 'blur',
                    },
                  
                  ]"
                >
                  <el-input v-model="registro.localidad" :min="8"></el-input>
                </el-form-item>
              </el-col>
              <el-col :md="12">
                <el-form-item
                  label="Provincia"
                  prop="provincia"
                  :rules="[
                    {
                      required: true,
                      message: 'campo obligatorio',
                      trigger: 'blur',
                    },
                  
                  ]"
                >
                  <el-select
                    v-model="registro.provincia"
                    style="width:100%"
                    placeholder
                    clearable
                    filterable
                    auto-complete="nope"
                  >
                    <el-option
                      v-for="item in provincias"
                      :key="item.id"
                      :label="item.nombre"
                      :value="item.nombre"
                    ></el-option>
                  </el-select>

                  <!-- <el-input v-model="registro.pass" :min="8" ></el-input> -->
                </el-form-item>
              </el-col>
              <el-col :md="24" :offset="0">
                <el-radio-group
                  v-model="registro.entrega"
                  style="padding-bottom:20px; margin-top:10px"
                >
                  <el-radio label="misma">Utilizar como dirección de entrega</el-radio>
                  <el-radio label="otra">Agregar una nueva dirección de entrega</el-radio>
                </el-radio-group>
              </el-col>
              <div
                style="border-top:1px solid #f1f1f1; clear:both; padding:10px"
                v-if="registro.entrega === 'otra'"
              >
                <el-col :md="16">
                  <el-form-item
                    label="Dirección de Entrega"
                    prop="entrega_direccion"
                    :rules="[
                      {
                        required: true,
                        message: 'campo obligatorio',
                        trigger: 'blur',
                      },
                    
                    ]"
                  >
                    <el-input v-model="registro.entrega_direccion" :min="8"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :md="12">
                  <el-form-item
                    label="Localidad de emtrega"
                    prop="entrega_localidad"
                    :rules="[
                      {
                        required: true,
                        message: 'campo obligatorio',
                        trigger: 'blur',
                      },
                    
                    ]"
                  >
                    <el-input v-model="registro.entrega_localidad" :min="8"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :md="12">
                  <el-form-item
                    label="Provincia de Entrega"
                    prop="entrega_provincia"
                    :rules="[
                      {
                        required: true,
                        message: 'campo obligatorio',
                        trigger: 'blur',
                      },
                    
                    ]"
                  >
                    <el-select
                      v-model="registro.entrega_provincia"
                      style="width:100%"
                      placeholder
                      clearable
                      filterable
                      auto-complete="nope"
                    >
                      <el-option
                        v-for="item in provincias"
                        :key="item.id"
                        :label="item.nombre"
                        :value="item.nombre"
                      ></el-option>
                    </el-select>

                    <!-- <el-input v-model="registro.pass" :min="8" ></el-input> -->
                  </el-form-item>
                </el-col>
              </div>
              <el-col :md="24" :offset="0">
                <hr />
              </el-col>

              <el-col :md="{ span: 4, offset: 14 }" :sm="{ span: 6, offset: 10 }" :xs="24">
                <el-button class="btn-block" type="link" @click="vista = 'login'">regresar</el-button>
              </el-col>
              <el-col :md="6" :sm="8" :offset="0">
                <el-form-item>
                  <el-button
                    @loading="cargando"
                    type="primary"
                    class="btn-block"
                    :loading="registrando"
                    @click="registrarUsuario('registro')"
                  >REGISTRARME</el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { HTTP } from "@/axios"
import provincias from "@/assets/provincias.json"
export default {
  data() {
    return {
      recuperando: false,
      registrando: false,
      cargando: false,
      agregado: false,
      vista: 'login',
      provincias: provincias.provincias,
      login: {},
      recuperar: {},
      registro: {
        entrega: 'misma'
      }
    };
  },
  created() {
    if (this.$route.query.vista) {
      this.vista = this.$route.query.vista
    }
  },
  methods: {
    submitForm(formName) {
      console.log('for');
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.cargando = true
          window.sessionStorage.clear()
          //  if(this.login.user === 'admin')
          //       {
          //           this.$router.push({name: 'catalogo'})
          //           window.sessionStorage.setItem('cliente', 1)
          //           window.sessionStorage.setItem('admin', 1)
          //       }
          //       if(this.login.user === 'cliente')
          //       {
          //           this.$router.push({name: 'catalogo'})
          //           window.sessionStorage.setItem('cliente', 1)
          //       }
          HTTP.post('/login', this.login)
            .then(res => {
              console.log(res);
              window.sessionStorage.setItem('cliente', JSON.stringify(res.data))
              if(res.data.rol == 99)
              {
                window.sessionStorage.removeItem('mantenimiento')
                this.$router.push({ name: 'admin' })
              }
              else{
            
                this.$router.push({ name: 'catalogo' })
              }
              setTimeout(() => {
                this.cargando = false
               
              }, 100);
              
            })
            .catch(() => {
              this.$alert('Datos incorrectos o usuario no habilitado', { type: 'error' })
              this.cargando = false
            })
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    registrarUsuario(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.registrando = true
          HTTP.post('/noa/register', this.registro)
            .then(res => {
              this.registrando = false
              this.agregado = true
              console.log(res)
            })
            .catch(e => {
              this.registrando = false
              this.$alert('No se pudo crear el cliente, verifique los datos ingresados y reitente')

              if (e.response) {
                if (e.response.status === 422) {
                  this.$alert('El CUIT: ' + this.registro.cuit + ' ya posee un registro. Si no recuerda sus datos de accesos presione el boton Regresar y seleccione la opción OLVIDE MIS DATOS ', e)
                }
              }

            })
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    recuperarPass() {
      if (this.recuperar.cuit) {
        this.recuperando = true
        HTTP.post('/recuperarPass', { cuit: this.recuperar.cuit })
          .then(res => {
            this.recuperando = false
            this.$alert(`Tu nueva contraseña fue enviada a ${res.data}`, { type: 'success' })
this.vista = 'login'
        
          })
          .catch(() => {
            this.registrando = false
            this.$alert('No se pudo recuperar la contraseña, refresque el navegador y reintente')
          })

      }
      else {

        this.$alert('Debe ingresar al menos un dato', {
          type: 'error'
        })
      }
    }
  },
};
</script>

<style lang="scss">
.login > .el-card__header {
  padding: 10px;
  border-bottom: 1px solid #ebeef5;
  box-sizing: border-box;
  background: #d32019;
  color: white;
  line-height: 1;
  font-size: 18px;
}
.logo {
  max-width: 260px;
  width: 100%;
  display: block;
  margin: 10px auto;
  text-align: center;
}
.footer-bottom {
  min-height: calc(100vh - 100px);
  overflow-y: hidden;
}

@media screen and (max-width:768px) {
  .footer-bottom {
  min-height: calc(100vh - 100px);
  overflow-y: visible;
}
}
</style>
