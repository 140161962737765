<template>
  <div class="menu" ref="scrollTarget"   >
       
     

         
    <div class="container">
      <el-row :gutter="20">
        <el-col
          :md="{ span: 4, offset: 0 }"
          :sm="{ span: 4, offset: 0 }"
          :xs="10"
         
        >
          <img src="@/assets/logo.png" class="menu-logo" alt="" @click="goToHome" />
        </el-col>
        <el-col
          :xs="{ span: 14, offset: 0 }"
          :offset="0"
          class="hidden-sm-and-up"
        >
          <div style="margin-top:20px">
             <el-button
              size="small"
              type="link"
             @click="goToHome" 
             icon="el-icon-s-shop"
              style="padding:8px"
            ></el-button>
            <el-button
              size="small"
              type="link"
                @click="$router.push({ name: 'cuenta' })"
               style="padding:8px"
              
            >CUENTA</el-button>
            <!-- <i class="el-icon-user-solid"></i> -->

            <el-button
             @click="drawer = true"
              size="small"
              type="primary"
               style="padding:8px"
            >PEDIDO   <span v-if="agregados">({{agregados}})</span></el-button>
          </div>
        </el-col>

        <el-col
          :md="{ span: 10, offset: 1 }"
          :sm="{ span: 9, offset: 1 }"
          class="fondo"
        >
          <el-input
            popper-class="my-input"
            class="buscador"
            v-model="buscado"
            @keypress.native.enter="buscar"
            placeholder="Buscar productos por nombre, marca, código de barra o rubro"
          >
            <i
              class="el-icon-search el-input__icon"
              slot="suffix"
              @click="buscar"
              style="cursor: pointer"
            >
            </i>
          </el-input>
         
          <!-- <el-autocomplete
            popper-class="my-autocomplete"
            class="buscador"
            v-model="buscado"
              :fetch-suggestions="querySearchAsync"

            placeholder="Buscar productos"
            @select="buscar1"
          
            :select-when-unmatched="true"
            :trigger-on-focus="false"
          >
            <i
              class="el-icon-search el-input__icon"
              slot="suffix"
              @click="buscar"
              style="cursor:pointer"
            >
            </i>
            <template slot-scope="{ item }">
              <div class="value">
                {{ item.nombre.toLowerCase() }}
                <br />
                <small>{{ item.categoria }}</small> - 
                <small>{{ item.marca }}</small>
              </div>
            </template>
          </el-autocomplete> -->
           <small class="ayuda-buscar" >Busque por nombre, marca, código de barra o rubro</small>
        </el-col>
        <el-col :md="{ span: 9, offset: 0 }" :sm="{ span: 10, offset: 0 }">
          <div class="hidden-xs-only">
            <el-button
              type="link"
              icon="el-icon-user-solid"
              style="margin-right: 5px; margin-top: 20px; margin-right: 0px"
              @click="$router.push({ name: 'cuenta' })"
              >Mi cuenta</el-button
            >
            <!-- <i class="el-icon-user-solid"></i> -->

            <el-button
              @click="drawer = true"
              type="primary"
              icon="el-icon-shopping-cart-2"
              >Mi Pedido 
              <span v-if="agregados">({{agregados}})</span>
            </el-button>
 <!-- <el-button
              style="margin-left: 10px"
             
             class="contexto"
              type="info"
              icon="el-icon-switch-button"
            >Salir</el-button> -->
             <el-button
              style="margin-left: 10px"
             
            @click="exit"
              type="info"
              icon="el-icon-switch-button"
            >Salir</el-button>
            <el-button
              style="position:absolute; right:0; top:20px;"
              v-if="isAdmin"
              @click="$router.push({ name: 'admin' })"
              type="warning"
              icon="el-icon-s-tools"
           
            ></el-button>
          </div>

          <!-- <i class="el-icon-shopping-cart-2"></i> -->
        </el-col>
      </el-row>
    </div>
    <el-drawer
      :withHeader="false"
      title="Detalles del pedido"
      :visible.sync="drawer"
      :direction="direction"
      :size="'100%'"
    >
      <mi-pedido />
    </el-drawer>
  </div>
</template>

<script>
import { HTTP } from "@/axios"
import { EventBus } from "@/event-bus";
import MiPedido from "@/components/MiPedido";
import foMixins from "@/mixins/foMixins.js";
export default {
  name: "foHeader",
  components: { MiPedido },
  mixins: [foMixins],
  data() {
    return {
      links: [],
      agregados: parseInt(window.sessionStorage.getItem("agregados")) ? window.sessionStorage.getItem("agregados") :undefined,
      nombres: undefined,
      hits: 0,
      state: "",
      buscado: "",
      drawer: false,
      direction: "rtl",
    };
  },
    mounted () {
    document.addEventListener("backbutton", this.cerrarBack(), false);
  },
  beforeDestroy () {
 
 
  window.addEventListener("popstate", (event) => {
 this.cerrarBack(event)
    });
  
},
    

  created() {

EventBus.$on('agregados', (agregados) => {
  this.agregados = agregados  ? agregados : undefined;
})
    EventBus.$on('limpiarBusqueda', ()=> {
         this.buscado = ''
         setTimeout(() => {
            EventBus.$emit("filtrar", "nombre", this.buscado);
         }, 100);
    })
    EventBus.$on("cerrarDetalles", (d) => {
      this.drawer = d;
      this.actualizarPedido();
    });
  },
  methods: {
    cerrarBack()
    {
      console.log('cerrar')
      this.drawer = false;
    },
    
exit()
{
window.sessionStorage.clear();
window.localStorage.clear();

this.$router.push({name: "login"})
},
    goToHome()
    {
      console.log('tohome');
      if(this.$route.name === 'admin' || this.$route.name === 'detallePedido' || this.$route.name === 'producto')
      {
         this.$router.push({name:'catalogo', query:{pagina:1}})
      }
      else{
this.buscado = ''
   setTimeout(() => {
        EventBus.$emit("eliminarFiltros", [],[]);
      }, 500);
      }
      
    },
     buscar1(item) {
      console.log(item);
     setTimeout(() => {
        EventBus.$emit("filtrar", "id_producto", item.id);
      }, 100);
     },
    
    querySearchAsync(queryString, cb) {
      if (queryString.length >= 3) {
        HTTP.post("/buscar1", { buscar: queryString }).then((res) => {
          // console.log(res.data);
          let items = res.data.data;
          
            //  EventBus.$emit("encontrados", res.data);
          
         
          cb(items);
          // console.log(items);
        });
      }

      cb();
    },

    buscar() {
     
      setTimeout(() => {
        EventBus.$emit("filtrar", "nombre", this.buscado);
      }, 100);
      //  setTimeout(() => {

      //   console.log('item2',item);
      //   console.log('buscado', this.buscado);
      //   console.log('nombres', this.nombres);
      //   console.log('hits', this.hits);

      //   let value = item.value;

      //   if(item.nombre){
      //     //selecciono una opcion
      //     this.$router.push({name: 'busqueda', query:{nombre: item.nombre, seleccion:1}})
      //   }else{

      //     if((value || this.buscado) && this.hits)
      //     {
      //       this.$router.push({name: 'busqueda', query:{nombre: value || this.buscado, seleccion:0}, params:{nombres : this.nombres}})
      //     }else{
      //       this.$router.push({name: 'busqueda', query:{nombre: value || this.buscado, seleccion:0}})
      //     }

      //   }

      //  }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.ayuda-buscar {
 
    font-size: 12px;
    text-align: right;
    float: right;
    padding-top: 3px;
    color: #686868;

}
.menu {
  border-bottom: 1px solid #f1f1f1;
}
.buscador {
  width: 100%;
}
.ws {
  // float: right;
  clear: both;
  color: #111;
}
.ws img {
  height: 20px;
}
.fondo {
  margin-top: 20px;
  // background: #f1f1f1;
}
.socios {
  padding: 2px;
  background: #87c6ec;
  // border-bottom: 1px solid #87C6EC;
  margin-bottom: 30px;
}
.logo {
  height: 40px;
  cursor: pointer;
}
.my-autocomplete {
  width: 100%;

  li {
    line-height: normal;
    padding: 7px;

    .value {
      text-overflow: ellipsis;
      overflow: hidden;
      line-height: 1.2;
      padding: 8px 0px;
      border-bottom: 1px solid #f1f1f1;
    }
    .value small {
      color: #87c6ec;
      font-weight: bold;
    }

    .link {
      font-size: 12px;
      color: #b4b4b4;
    }
  }
}
@media screen and (max-width:992px) {
  .contexto{
    display: none;
  }
   .sintexto{
    display: block;
  }
}
@media screen and (min-width:992px) {
  .contexto{
    display: block;
  }
   .sintexto{
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .socios {
    margin-bottom: 20px;
  }
  .ayuda-buscar{
    margin-bottom: 20px;
  }
  .buscador {
    width: 100%;
    margin: 20px auto;
    margin-bottom: 0px;
  }
  .fondo {
    margin-top: 15px;
    background: #f1f1f1;
  }
}

.menu-logo {
  width: 100%;
  padding: 20px;
  display: block;
  cursor: pointer;
}
#nav {
  float: right;
}
#nav ul {
  list-style: none;
  display: table;
  *display: block;
  width: 100%;
  margin: 0;
  padding: 0;
}

#nav ul li {
  display: inline-block;
  font-weight: bold;
  font-size: 14px;
  // min-width: 90px;
  width: auto;
  cursor: pointer;

  border-right: 1px solid #999;
  text-align: center;
  white-space: nowrap;
  padding: 0px 10px;
}
#nav ul li:last-child {
  border-right: 0px;
}
@media screen and (max-width: 768px) {
  .menu-logo {
  width: 100%;
  padding: 0px;
  display: block;
  cursor: pointer;
  margin-top: 20px;
}
  
}
</style>