<template>
  <div>
    <el-dialog
      :title="producto_editar ? 'Editar Producto #' + producto_editar.id : 'Agregar producto'"
      :visible.sync="modalProducto"
      width="90%"
      :top="'1vh'"
    >
      <el-row :gutter="10">
        <el-col :md="16" :offset="0">
          <el-form :model="producto" ref="producto" size="small" :label-position="'top'">
            <el-col :md="13" :offset="0">
              <el-form-item
                label="Nombre"
                prop="nombre"
                :rules="[
                  { required: true, message: 'obligatorio', trigger: 'blur' },
                ]"
              >
                <el-input v-model="producto.nombre"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="4" :offset="0">
              <el-form-item
                label="Código"
                prop="codigo"
                :rules="[
                  { required: true, message: 'obligatorio', trigger: 'blur' },
                ]"
              >
                <el-input v-model="producto.codigo" style="width:100%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="4" :offset="0">
              <el-form-item
                label="Precio de Venta"
                prop="precio"
                :rules="[
                  { required: true, message: 'obligatorio', trigger: 'blur' },
                ]"
              >
                <el-input-number
                  v-model="producto.precio"
                  :controls="false"
                  style="width:100%"
                  :min="0"
                ></el-input-number>
                <!-- <el-checkbox v-model="producto.iva" :checked="producto.iva ? true : false" >IVA</el-checkbox> -->
                
              </el-form-item>
            </el-col>
            <el-col :md="3" :offset="0">
              <el-form-item label="Oferta %">
                <el-input-number
                  v-model="producto.oferta"
                  :controls="false"
                  style="width:100%"
                  :min="1"
                  :max="producto.precio"
                ></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :md="12" :offset="0">
              <el-form-item label="Categoría">
                <el-select v-model="producto.categoria_id" style="width:100%" clearable filterable>
                  <el-option v-for="c in categorias" :key="c.id" :label="c.nombre" :value="c.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="12" :offset="0">
              <el-form-item label="Marca">
                <el-select v-model="producto.marca_id" style="width:100%" clearable filterable>
                  <el-option v-for="m in marcas" :key="m.id" :label="m.nombre" :value="m.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :md="10" :offset="0">
              <el-form-item label="Código de Barra">
                <el-input
                  v-model="producto.codigo_barra"
                  :controls="false"
                  style="width:100%"
                  :min="0"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="4" :offset="0">
              <el-form-item label="Stock">
                <el-input-number
                  v-model="producto.stock"
                  :controls="false"
                  style="width:100%"
                  :min="0"
                ></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :md="3" :offset="0">
              <el-form-item label="Bultos">
                <el-input-number
                  v-model="producto.bultos"
                  :controls="false"
                  style="width:100%"
                  :min="0"
                ></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :md="4" :offset="0">
              <el-form-item label="Cant x Bulto">
                <el-input-number
                  v-model="producto.xbulto"
                  :controls="false"
                  style="width:100%"
                  :min="0"
                ></el-input-number>
              </el-form-item>
            </el-col>

            <el-col :md="8" :offset="0">
              <el-form-item label="Descripción" prop="descripcion">
                <el-input type="textarea" :rows="7" v-model="producto.descripcion"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="16" :offset="0">
              <el-form-item label="Imagenes">
             
              
                <el-upload
                  :action="uploadFilesUrl"
                  ref="upload"
                  list-type="picture-card"
                  :auto-upload="false"
                  :data="{id:producto_id}"
                >
                  <i slot="default" class="el-icon-plus"></i>
                  <div slot="file" slot-scope="{ file }">
                    <img class="el-upload-list__item-thumbnail" :src="file.url" alt />
                    <span class="el-upload-list__item-actions">
                      <span
                        class="el-upload-list__item-preview"
                        @click="handlePictureCardPreview(file)"
                      >
                        <i class="el-icon-zoom-in"></i>
                      </span>
                      <span
                        v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="handleDownload(file)"
                      >
                        <i class="el-icon-download"></i>
                      </span>
                      <span
                        v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="handleRemove(file)"
                      >
                        <i class="el-icon-delete"></i>
                      </span>
                    </span>
                  </div>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt />
                </el-dialog>
                   <el-row :gutter="5" v-if="producto.fotos && producto.fotos.length">
                  <el-col :md="6"  v-for="(f,index) in producto.fotos" :key="f.id">
                    <el-card :body-style="{ padding: '5px' }" style="height:150px; margin-top:10px; position:relative">
                      <el-button @click="eliminarImagen(f.id, index)" size="mini" class="btn-delete-img" icon="el-icon-delete" type="danger" circle></el-button>
                       <img style="width:100%" :src="FILES_PATH + f.url" alt="">
                    </el-card>
                   
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
           
          </el-form>
        </el-col>
        <el-col :md="8">
          <div style="background:#f1f1f1">
              <table class="table table-sm table-condensed table-bordered table-hover table-striped">
<tr>
<th>Fecha</th>
<th>Precio Actual <br> Oferta actual</th>
<th>Precio Anterior <br> Oferta anterior </th>

</tr>
<tr v-for="h in historial" :key="h.fecha">
<td>
{{$date(h.fecha).format('DD/MM/YYYY HH:mm:ss')}}
</td>
<td>
{{h.precio_actual | currency}} <br>
{{h.oferta_actual | currency}}
</td>
<td>
{{h.precio_anterior | currency}} <br>
{{h.oferta_anterior | currency}}
</td>
</tr>
              </table>
          </div>
        </el-col>
      </el-row>

      <span slot="footer">
        <el-button
          @click="eliminarProducto(producto)"
          v-if="producto.id"
          icon="el-icon-delete"
          type="danger"
          style="float:left"
          plain
        >ELIMINAR {{producto.nombre}}</el-button>
        <el-button @click="modalProducto = false">Cancelar</el-button>
        <el-button
          type="primary"
          @click="agregarProducto('producto')"
          :loading="agregando"
        >GUARDAR CAMBIOS</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";
import { HTTP, uploadFilesUrl,FILES_PATH } from "@/axios";
export default {
  props: ["producto_editar"],

  data() {
    return {
      FILES_PATH:FILES_PATH,
      uploadFilesUrl: uploadFilesUrl,
      agregando: false,
      modalProducto: false,
      producto: {},
      categorias: [],
      marcas: [],
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      producto_id: 0,
      historial: [],
    };
  },
  watch: {
    producto_editar(val) {
      if (val) {
       this.historialPrecio(val);
      }
    }
  },
  created() {
    console.log("this.producto_editar", this.producto_editar);
    EventBus.$on("modalProducto", (m) => {
      this.modalProducto = m;
      this.producto = this.producto_editar;
         this.producto_id = this.producto_editar.id;
    });
    if (this.producto_editar) {
      this.producto = this.producto_editar;
      this.producto_id = this.producto_editar.id;
    }
    this.getCategorias();
    this.getMarcas();
   
  },
  methods: {
    eliminarImagen(imagen, index)
    {
 this.$confirm("Confirme la operación", "Eliminar Imágen", {
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
        type: "danger",
      }).then(() => {
        HTTP.post("/noa/eliminarImagen", { id: imagen}).then((res) => {
        
          if (res.data) {
          this.producto.fotos.splice(index,1)
            this.$message({
              type: "success",
              message: "imágen eliminada",
            });
          } else {
            this.$message({
              type: "error",
              message: "no se pudo eliminar la imágen",
            });
          }
        });
      });
    },
    eliminarProducto(producto) {
      this.$confirm("Eliminar " + producto.nombre, "Eliminar producto", {
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
        type: "danger",
      }).then(() => {
        HTTP.post("/noa/eliminarProducto", { id: producto.id }).then((res) => {
          this.modalProducto = false;
          if (res.data) {
            EventBus.$emit("actualizarLista", true);
            this.$message({
              type: "success",
              message: "producto eliminado",
            });
          } else {
            this.$message({
              type: "error",
              message: "no se pudo eliminar el producto",
            });
          }
        });
      });
      // .catch(() => {
      //   this.$message({
      //     type: 'info',
      //     message: 'Delete canceled'
      //   });
      // });
    },
    historialPrecio(val)
    {
     HTTP.post('/admin/historialPrecio',{codigo:val.codigo})
         .then((res) => {
         this.historial = res.data;
       
         })
    },
    getCategorias() {
      HTTP.get("/noa/getCategorias").then((res) => {
        this.categorias = res.data;
      });
    },
    getMarcas() {
      HTTP.get("/noa/getMarcas").then((res) => {
        this.marcas = res.data;
      });
    },
    agregarProducto(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.agregando = true;
          HTTP.post("/noa/agregarProducto", this.producto)
            .then((res) => {
              console.log(res.data);
              console.log('this.producto_id',this.producto_id);

              this.agregando = false;
              this.resetForm(formName);
              this.producto = {};
              if (res.data === -1) {
                
                setTimeout(() => {
                  this.$refs.upload.submit();
                   EventBus.$emit("actualizarLista", true);
                this.modalProducto = false;
                }, 100);
               

                this.$message({
                  message: "Producto actualizado.",
                  type: "success",
                });
              } else {
                this.producto_id = res.data;
                setTimeout(() => {
                  this.$refs.upload.submit();
                }, 100);
                this.$alert("Producto agregado", { type: "success" });
              }
            })
            .catch((e) => {
              this.$alert("No se pudo agregar el producto " + e, {
                type: "error",
              });
              this.agregando = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleRemove(file) {
      console.log(file);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      console.log(file);
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-delete-img{
  position: absolute;
    right: 10px;
    top: 10px;
    z-index: 99;
}</style>
