import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import "@/plugins/Dayjs";
import Vue2Filters from "vue2-filters";
import  VueScrollTo from "vue-scrollto";
import BackToTop from 'vue-backtotop'
import VueMobileDetection from "vue-mobile-detection";



Vue.config.productionTip = false

var Vue2FiltersConfig = {
  capitalize: {
    onlyFirstLetter: false,
  },
  number: {
    format: "00.0",
    thousandsSeparator: ".",
    decimalSeparator: ",",
  },
  bytes: {
    decimalDigits: 2,
  },
  percent: {
    decimalDigits: 2,
    multiplier: 100,
    decimalSeparator: ".",
  },
  currency: {
    symbol: "$",
    decimalDigits: 2,
    thousandsSeparator: ".",
    decimalSeparator: ",",
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    showPlusSign: false,
  },
  pluralize: {
    includeNumber: false,
  },
  ordinal: {
    includeNumber: false,
  },
};

Vue.use(Vue2Filters, Vue2FiltersConfig);
Vue.use(VueScrollTo);
Vue.use(BackToTop)
Vue.use(VueMobileDetection)



// eslint-disable-next-line
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
