import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import catalogo from "../views/catalogo.vue";
import login from "../views/login.vue";
import mantenimiento from "../views/mantenimiento.vue";

Vue.use(VueRouter)

const routes = [{
    path: "/",
    name: "home",
    component: catalogo,
  },
  {
    path: "/catalogo",
    name: "catalogo",
    component: catalogo,
  },
  {
    path: "/login",
    name: "login",
    component: login,

  },
  {
    path: "/mi-cuenta",
    name: "cuenta",
    // route level code-splitting
    // this generates a separate chunk (admin.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      requiresAuth: true
    },
    component: () =>
      import( /* webpackChunkName: "admin" */ "../views/cuenta.vue"),
  },
  {
    path: "/pedido/:pedido",
    name: "detallePedido",
    // route level code-splitting
    // this generates a separate chunk (admin.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
  meta: {
      requiresAuth: true
    },
    component: () =>
      import( /* webpackChunkName: "admin" */ "../views/PedidoDetalle.vue"),
  },
  {
    path: "/producto/:codigo",
    name: "producto",
    // route level code-splitting
    // this generates a separate chunk (admin.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
  meta: {
      requiresAuth: true
    },
    component: () =>
      import( /* webpackChunkName: "admin" */ "../views/Producto.vue"),
  },
  {
    path: "/admin",
    name: "admin",
    // route level code-splitting
    // this generates a separate chunk (admin.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
      meta: {
      requiresAuth: true
    },
    component: () =>
      import( /* webpackChunkName: "admin" */ "../views/admin.vue"),
  },
  {
    path: "/mantenimiento",
    name: "mantenimiento",
    // route level code-splitting
    // this generates a separate chunk (admin.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: mantenimiento,
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    };
  },

})
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  //  let login = window.sessionStorage.getItem("cliente") ? JSON.parse(window.sessionStorage.getItem("cliente")).id : 0;



  if (to.matched.some((record) => record.meta.requiresAuth)) {
    let auth = window.sessionStorage.getItem("cliente") ? JSON.parse(window.sessionStorage.getItem("cliente")).id : 0;
    // let status = window.sessionStorage.getItem("mantenimiento");
    if (!auth) {
      next({
        path: "/login",
      });
    } else {
 
        next();
      

    }
  } else {
    //  let status = window.sessionStorage.getItem("mantenimiento");
    // //  alert(status)
    // if (status) {
    //     // next('/mantenimiento').catch(() => { });
    //   } else {
        next();
      // }
  }
});



export default router