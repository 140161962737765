<template>
  <div class="grilla minH">
    
    <el-card
      class="card-grilla"
      shadow="always"
     
      :body-style="{ padding: '0px' }"
    >
      <el-row :gutter="20" class="listado" v-for="r in resultados" :key="r.id">
        <i class="el-icon-s-tools edit" v-if="isAdmin" @click="editar(r)"></i>
        <span class="edit2"  v-if="isAdmin" @click="verProducto(r)">
 <i class="el-icon-menu " v-if="isAdmin" @click="editar(r)"></i>
 Ver producto
        </span>
       
        <span :class="'visible' + r.activo" v-if="isAdmin" @click="ocultar(r)">
        <i class="el-icon-view " ></i>
          <span v-if="r.activo">visible</span> 
          <span v-else>NO visible</span> 
        </span>
        <el-col :md="3" :sm="4" :xs="12" :offset="0">
          <!-- {{ FILES_PATH + r.fotos[0].url}} -->
       
          <el-image
            v-if="r.fotos && r.fotos.length"
            class="p-img"
            :src="r.fotos[0].mini ? FILES_PATH + r.fotos[0].mini : FILES_PATH + r.fotos[0].url.replace('imagenes','mini')"
            :preview-src-list="r.fotos.map((f) => FILES_PATH + f.url)"
          >
           <div slot="error" class="image-slot">
          <img
            src="@/assets/no-img.jpg"
          
            style="width: 100%"
            alt=""
          />
      </div>
          </el-image>
          <img
            src="@/assets/no-img.jpg"
            v-if="!r.fotos.length"
            style="width: 100%"
            alt=""
          />
        </el-col>
        <el-col :md="12" :sm="13" :xs="12" :offset="0">
          <strong>ARTÍCULO <span style="font-weight: bold;
    color: #000;
}">{{r.codigo}}</span>
<span  v-if="r.codigo_barra && r.codigo_barra != '-'"> | COD </span>
<span v-if="r.codigo_barra && r.codigo_barra != '-'" style="font-weight: bold;
    color: #000;
}">{{r.codigo_barra}}</span>
</strong>
          <br />
          {{ r.nombre.toLowerCase() }}

          <br />
          <small class="marca">
            <!-- <el-button plain size="mini" type="default" style="padding:3px; margin-left:-3px; font-size:11px"> MARCA</el-button> -->
            <el-button
              plain
              size="mini"
              type="warning"
              style="padding: 3px; margin-left: 3px; font-size: 11px"
            >
              {{ r.marca }}</el-button
            >
          </small>
          <small class="cat" v-if="r.categoria">
            <!-- <el-button plain size="mini" type="default" style="padding:3px; margin-left:3px; font-size:11px"> RUBRO</el-button> -->
            <el-button
              plain
              size="mini"
              type="danger"
              style="padding: 3px; margin-left: 3px; font-size: 11px"
              v-for="t in r.categoria.split('/')"
              :key="t"
            >
              {{ t }}</el-button
            >
          </small>

          <small class="bultos">
           
            <el-button
              plain
              size="mini"
              v-if="r.xbulto"
              type="default"
              style="
                padding: 3px;
                margin-left: 3px;
                margin-top: 3px;
                font-size: 11px;
              "
            >
              UNIDADES x BULTO: {{ r.xbulto }}</el-button
            >
            <el-button
              plain
              size="mini"
              v-if="r.descripcion"
              @click="getDetalles(r.id)"
              type="success"
              style="
                padding: 3px;
                margin-left: 3px;
                margin-top: 4px;
                font-size: 11px;
              "
            >
              DESCRIPCIÓN
            </el-button>
          </small>
          <span :class="'zigzag'" v-if="isOferta(r)">
            {{ (100 - (r.oferta * 100 / r.precio)).toFixed(2).replace(/\.00$/, '')}}% descuento
            </span>
          <div class="hidden-sm-and-up separador-movil"></div>
        </el-col>
        <el-col :md="{ span: 8, offset: 1 }" :sm="7" v-if="!verPrecios">
          <span class="no-login"> Para ver precios o realizar un pedido.</span>

          <el-button
            type="primary"
            size="small"
            @click="$router.push({ name: 'login' })"
            >Ingresar</el-button
          >
          <el-button
            type="info"
            size="small"
            @click="
              $router.push({ name: 'login', query: { vista: 'registro' } })
            "
            >Registrarse</el-button
          >
        </el-col>
        <el-col :md="5" :sm="5" :xs="12" :offset="0" v-if="verPrecios">
          <strong  v-if="!r.iva">precio <span style="text-transform:lowercase">sin</span> IVA : </strong>
          <strong v-if="r.iva">precio <span style="text-transform:lowercase">con</span> IVA : </strong>
          <br />
          <span class="precio">
            {{ r.precio | currency }}
          </span>
          <br />
          <strong style="color: #9c27b0" v-if="isOferta(r) && !r.iva"
            >precio oferta <span style="text-transform:lowercase">sin</span> IVA:</strong
          >
             <strong style="color: #9c27b0" v-if="isOferta(r) && r.iva"
            >precio oferta <span style="text-transform:lowercase">con</span> IVA:</strong
          >
          <br v-if="isOferta(r)" />
          <span class="precio oferta" v-if="isOferta(r)">
            {{ r.oferta | currency }}
          </span>
        </el-col>
        <el-col :md="4" :sm="5" :xs="12" :offset="0" v-if="verPrecios">
          <strong   v-if="r.precio">cantidad:</strong>
          <br />
          <el-input-number
            v-model="r.cantidad"
            :disabled="r.agregado3"
            style="width: 100%"
            size="mini"
            :min="1"
              v-if="r.precio"
          ></el-input-number>

          <el-button
            v-if="r.precio"
            type="success"
            :disabled="r.agregado3"
            size="mini"
            @click="agregar(r)"
            :loading="cargando == r.id"
            class="btn-grilla"
            :plain="r.agregado ? false : true"
          >
            <span v-if="r.agregado">AGREGADO</span>
            <span v-else>AGREGAR</span>
          </el-button>
        </el-col>
        <el-col :md="24">
          <div class="descripcion" v-if="detalles == r.id">
            <strong>descripción:</strong> <br />
            {{ r.descripcion }}
          </div>
        </el-col>
      </el-row>
    </el-card>
    <nuevo-producto v-if="isAdmin" :producto_editar="selectProducto" />
      
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";
import { FILES_PATH, HTTP } from "@/axios";
import foMixins from "@/mixins/foMixins.js";
import NuevoProducto from "@/components/NuevoProducto.vue";

export default {
  props: ["datos"],
  components: { NuevoProducto },
  mixins: [foMixins],
  data() {
    return {
      FILES_PATH: FILES_PATH,
      resultados: this.datos,
      cargando: false,
      detalles: false,
      selectProducto: {},
      verPrecios: window.sessionStorage.getItem("cliente") ? JSON.parse(window.sessionStorage.getItem("cliente")).habilitado : false,
    
     
    };
  },
  created() {
    this.resultados = this.datos;
   
    EventBus.$on("filtrados", (f) => {
      this.resultados = f;
    });
  },
  methods: {
    isOferta(r) {
      return r.oferta < r.precio && r.oferta  && r.oferta != 1;
    },
    editar(r) {
      this.selectProducto = r;
      setTimeout(() => {
        EventBus.$emit("modalProducto", true);
        console.log(this.selectProducto);
      }, 300);
    },
       ocultar(r) {
      let titulo = r.activo ? '¿Desea ocultar el producto?' : '¿Desea mostrar el producto?';
      let mensaje = r.activo ? 'Se ocultará el producto ' + r.codigo : 'Se mostrará el producto ' + r.codigo;
      this.$confirm(mensaje, titulo, {
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        type: 'success',
      }).then(() => {
        HTTP.post('/admin/ocularProducto', {codigo:r.codigo , activo: r.activo ? 0 : 1}).then(() => {
          this.$message({
            type: 'success',
            message: 'Se ha realizado la operación, refreque la página para ver los cambios'
          });
         
        }).catch(() => {
          this.$message({
            type: 'error',
            message: 'No se ha podido realizar la operación'
          });
        });
      }).catch(() => {
        
      });
      
    },
    verProducto(p)
    {
      this.$router.push({name: 'producto', params: {codigo: p.codigo}});
    },
    getDetalles(id) {
      if (this.detalles === id) {
        this.detalles = false;
      } else {
        this.detalles = id;
      }
    },
    agregar(r) {
      console.log(r);
      if (!r.cantidad) {
        this.$alert("Por favor seleccione la cantidad de artículos", {
          type: "info",
        });
      } else {
        this.cargando = r.id;
        HTTP.post("/noa/agregarProductoPedido", {
          cliente: this.cliente,
          user: this.user,
          pedido_id: this.pedido,
          producto_id: r.id,
          cantidad: r.cantidad,
          precio: this.isOferta(r) ?  r.oferta : r.precio,
        })
          .then((res) => {
              
            if (res.data) {
           
              // if (parseInt(window.sessionStorage.getItem("pedido")) < 0) {
                window.sessionStorage.setItem("pedido", res.data.pedido_id);
                window.sessionStorage.setItem("agregados", res.data.agregados);
                this.actualizarPedido(res.data.pedido_id)
               EventBus.$emit("agregados", res.data.agregados);
              // }
              let index = this.resultados.findIndex((p) => p.id === r.id);
              //  setTimeout(() => {
              this.resultados[index].agregado = true;
              //  }, 100);
              this.$message({
                message: r.nombre + " agregado",
                type: "success",
              });
            }
          
            setTimeout(() => {
               EventBus.$emit('actualizarCarro', true)
                 this.cargando = false;
            }, 100);
          })
          .catch((e) => {
            this.$alert(
              "No se pudo agregar el producto. Refreque la página y reintente " +
                e
            );
            this.cargando = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.minH{
  min-height: calc(100vh - 300px);
  // overflow-y: hidden;
}
.zigzag30 {
    
    display: inline-block;
    background: linear-gradient(-137deg, #ebe94f 6px, transparent 0) 0 5px, linear-gradient(320deg, #ebe94f 5px, #fff 0) 0 5px;
    background-color: #ebe94f;
    background-position: left bottom;
    background-repeat: repeat-y;
    background-size: 10px 10px;
    margin-top: 5px;
    padding:1px 15px;
    font-size: 13px;
    padding-right: 5px;
 }
 .zigzag50 {
    
    display: inline-block;
    background: linear-gradient(-137deg, #ffc166 6px, transparent 0) 0 5px, linear-gradient(320deg, #ffc166 5px, #fff 0) 0 5px;
    background-color: #ffc166;
    background-position: left bottom;
    background-repeat: repeat-y;
    background-size: 10px 10px;
    margin-top: 5px;
    padding:1px 15px;
    font-size: 13px;
    padding-right: 5px;
    color: #000;
 }
 .zigzag15 {
    
    display: inline-block;
    background: linear-gradient(-137deg, #4a148c 6px, transparent 0) 0 5px, linear-gradient(320deg, #4a148c 5px, #fff 0) 0 5px;
    background-color: #4a148c;
    background-position: left bottom;
    background-repeat: repeat-y;
    background-size: 10px 10px;
    margin-top: 5px;
    padding:1px 15px;
    font-size: 13px;
    padding-right: 5px;
    color: #FFF;
 }
  .zigzag10 {
    
    display: inline-block;
    background: linear-gradient(-137deg, #e91e63 6px, transparent 0) 0 5px, linear-gradient(320deg, #e91e63 5px, #fff 0) 0 5px;
    background-color: #e91e63;
    background-position: left bottom;
    background-repeat: repeat-y;
    background-size: 10px 10px;
    margin-top: 5px;
    padding:1px 15px;
    font-size: 13px;
    padding-right: 5px;
    color: #FFF;
 }
 .zigzag {
    
    display: inline-block;
    background: linear-gradient(-137deg, #ebe94f 6px, transparent 0) 0 5px, linear-gradient(320deg, #ebe94f 5px, #fff 0) 0 5px;
    background-color: #ebe94f;
    background-position: left bottom;
    background-repeat: repeat-y;
    background-size: 10px 10px;
    margin-top: 5px;
    padding:1px 15px;
    font-size: 13px;
    padding-right: 5px;
 }
.edit {
  position: absolute;
  right: 15px;
  top: 6px;
  color: #9e9e9e;
  cursor: pointer;
  z-index: 99;
}
.edit2 {
  position: absolute;
  right: 125px;
  top: 6px;
  color: #38037e;
  cursor: pointer;
  z-index: 99;
  font-size:12px;
}
.visible0 {
  position: absolute;
  right: 45px;
  top: 7px;
  font-size: 13px;
  color: #c501a4;
  cursor: pointer;
  z-index: 99;
}
.visible0 > span { margin-left: 2px;}
.visible1 {
  position: absolute;
  right: 45px;
  top: 7px;
  font-size: 13px;
  color: #01881e;
  cursor: pointer;
  z-index: 99;
}
.visible1 > span { margin-left: 2px;}
.no-login {
  text-transform: lowercase;
  font-size: 14px;
  text-align: left;
  line-height: 1.2;
  display: block;
  margin-bottom: 10px;
}
.separador-movil {
  margin: 20px 0px;
  border-bottom: 3px dashed #fa81aa;
}
.icon {
  height: 16px;
}
.precio {
  font-size: 22px;
  text-align: center;
}
.oferta {
  background: #ffeb3b;
  padding: 0px 1px;
}
.marca {
  margin-left: -3px;
}
.bultos {
  margin-left: -3px;
  display: block;
}
.grilla {
  line-height: 1.2;
  text-transform: capitalize;
}
.listado {
  padding: 20px;
  border-bottom: 1px solid #f1f1f1;
  color: #000;
  transition: all 0.2s;
}
.listado:hover {
  background: #fcd8d852;
}
.listado strong {
  color: #e91e63;
  font-size: 12px;
}
.btn-grilla {
  margin-top: 5px;
  width: 100%;
}
.descripcion {
  margin-top: 10px;
  border-top: 2px dashed #ffcdd2;
  padding: 10px 0px;
}
.p-img {
  width: 100%; object-fit: fill; max-height:100px;
}
@media screen and (max-width: 768px) {
  .precio {
    // display: none;
    font-size: 30px;
  }
  .card-grilla {
    background: #f6f9fc;
    box-shadow: none;
  }
  .p-img {
  width: 100%; object-fit: contain; max-height:160px;
}
  .listado {
    border: 1px solid #f1f1f1;
    margin-bottom: 20px;
    background: #fff;
  }
}
</style>
