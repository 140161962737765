<template>
  <div id="app">
      
     <FoHeader v-if="$route.name !== 'login' && $route.name !== 'registro' && $route.name !== 'detallePedido' && $route.name !== 'mantenimiento'"  />


     <router-view></router-view>

    <div class="piePag" v-if="$route.name !== 'login' && $route.name !== 'registro' && $route.name !== 'detallePedido'" >
     <back-to-top text="Back to top"  visibleoffset="500" right="0px">
       <el-button circle type="primary"  icon="el-icon-top"></el-button>
       
     </back-to-top>

      <el-row :gutter="20">
        <el-col :md="{span:8, offset:2}" :xs="{span:20, offset:2}" :offset="0">
          <strong>NOA Comercial S.R.L {{new Date().getFullYear()}}</strong>
          <br>
          <ul>
            <li>Teléfono: <a href="tel:+543812339592" class=""><strong>381 2339592</strong></a> </li>
            <li>Whatsapp: <a href="https://api.whatsapp.com/send?phone=543812339592&text=Consulta%20Noa%20Comercial" class=""><strong>381 2339592</strong></a> </li>
            <li>Mail: <a href="mailto:info@noacomercial.com.ar"><strong>info@noacomercial.com.ar</strong></a> </li>
            
          </ul>
        </el-col>
        <el-col :md="13" :offset="0" v-if="$route.name == 'catalogo'">
          <small style="display:block; text-align:right">Imágenes de carácter ilustrativo, la presentación del producto puede variar</small>
        </el-col>
      </el-row>
      
    </div>
  </div>
</template>

<script>
import FoHeader from '@/components/FoHeader.vue'
import {HTTP} from '@/axios'


export default {
  name: 'app',
  components: {
    FoHeader,
   
  },
  data() {
    return {
      top: false,
      cliente: window.sessionStorage.getItem('cliente') ? JSON.parse(window.sessionStorage.getItem('cliente')) : {}
    }
  },
  watch: {
    $route(to, from) {
      // console.log(to);
      // console.log(from);
      let status = window.sessionStorage.getItem('mantenimiento') ? true : false;
      if(to.name !== 'login' && to.name !== 'admin' && from.name !== 'login' && !status && this.cliente.rol != 99  )
      {
        this.config()
      }
      
    }
  } ,
  created() {
    this.config()
    setTimeout(() => {
      this.top = true
      console.log('top', this.top)
    }, 3000)
  },
  methods: {
    config() {
      HTTP.get('/config')
          .then(res => {
            console.log(res.data[0].mantenimiento );
            if (res.data[0].mantenimiento && this.$route.name !== 'admin' && this.cliente.rol !== 99)
            {
              window.sessionStorage.setItem('mantenimiento', 1)
              this.$router.push({ name: 'mantenimiento' }).catch(() => { })
              
            }
            
          })
          .catch(()=>{
            this.$alert('No se pudo recuperar la información del servidor')
          })
    }
  },
}
</script>

<style>
#app {height: 100%;   width: 100%;}
.piePag{
  background: #f6f9fc;
  width: 100%;
  /* margin:0 auto; */
  /* margin-bottom: 300px; */
  position: relative;
  padding: 30px 0px;
  border-top: 1px dashed #cce3fa;
  overflow: hidden;
  top:100%;
  left: 0;
  right:auto ;
}
</style>
