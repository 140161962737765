<template>
    <div>
        <el-row :gutter="20">
            <el-col :md="24" :offset="0">
                <img src="@/assets/logo.png"
                    style="display:block; width:360px; margin:40px auto; margin-bottom: 10px; text-align:center" alt="">
                <h1
                    style="text-align:center;margin-bottom: 100px; text-transform: uppercase; background:#CF2C19; padding:20px; color:#fff ">
                    Sitio web en mantenimiento
                    <br>
                    <small style="text-transform:lowercase ">Intente nuevamente más tarde</small>
                    <br>
                    <small style="text-transform:lowercase; font-size:12px; float:right ">{{this.$date(new Date()).format('DD/MM/YYYY HH:mm:ss')}}</small>
                </h1>
            </el-col>

        </el-row>

    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>